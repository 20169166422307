import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconComponent } from '../icon/icon.component';

export interface AvatarOptions {
  type: 'uploaded' | 'initials';
  href?: string;
  initials?: string;
  color_class?: string;
}

export type AvatarType = 'org' | 'user';

export const AVATAR_SIZES = ['extra-small', 'small', 'medium', 'large'] as const;
export type AvatarSize = typeof AVATAR_SIZES[number];

/**
 * This component handles all of our avatar use cases. It covers org and user
 * types, default and image uploaded version, along with the special community avatar.
 *
 * This currently depends on a complex configuration object, but represents the
 * implementations we currently see in webapp.
 */
@Component({
  standalone: true,
  selector: 'capra-avatar',
  imports: [CommonModule, IconComponent],
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  /** The avatar type `(org | user)` determines avatar style, specifically the border radius */
  @Input() type: AvatarType = 'org';

  @Input() size: AvatarSize = 'small';

  /** The avatar options object, content varied based on whether the avatar options has type `initials` or `uploaded`
   * `{ type: string; href?: string; initials?: string; color_class?: string; }`
   */
  @Input() options: AvatarOptions | undefined;

  /** An optional input when "true", the community-blue icon is displayed */
  @Input() community = false;

  /**
   * Determine if an image is present in the options
   */
  get hasImage() {
    return this.options && this.options.href;
  }
}
